<template>
    <div v-if="selectedTopic">
        <div class="card">
            <DataTable
                :value="programs"
                :loading="loading"
                responsive-layout="scroll"
                showGridlines
                :paginator="true"
                
                :rows="rowsPagValue"            
                :rowsPerPageOptions="[25,50,100,]"
                :rowHover="true"
                :first="firstPagValue"
                
                removableSort
                dataKey="id"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                v-model:filters="filters"
                filterDisplay="menu"
                :sort-field="sortFieldValue" 
                :sort-order="sortOrderValue" 
                @sort="onSort($event)"
                @page="onSort($event)"
                >
                <template #header>
                    <div v-if="selectedTopic">
                        <div v-if="fromTopic" style="display: flex; align-items: center;">
                            <h5 style="margin-bottom: 0;">Programmes for  {{selectedTopic.name}}</h5>
                            <Button 
                                class="p-button-raised p-button-rounded my-3 p-button-help mx-3" 
                                icon="pi pi-question-circle" 
                                @click="goToHeaderPage('programOverview-header')" >
                            </Button>
                        </div>
                        <div v-else>
                            <h5 style="margin-bottom: 0;">Programmes</h5>
                            <Button 
                                class="p-button-raised p-button-rounded my-3 p-button-help mx-3" 
                                icon="pi pi-question-circle" 
                                @click="goToHeaderPage('programOverview-header')" >
                            </Button>
                        </div>
                    </div>

                    <div class="table-header row gy-3 d-flex flex-row justify-content-between">
                        <!-- add Button -->
                        <!-- <div class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start"> -->
                        <div 
                            class="  d-flex flex-row justify-content-md-start"
                        >
                            <CreateProgram />
                        </div>

                        <!--showSegmentations, Clear Button , Filters, KeywordSearch -->
                        <!-- <div class="col-sm-12 col-md-8"> -->
                        <div style="display: flex; justify-content: flex-end; width: 100%;">
                            <div class="d-flex flex-row justify-content-md-end">
                                <Button
                                    type="button"
                                    icon="pi pi-filter-slash"
                                    label="Clear"
                                    class="p-button-outlined me-4"
                                    @click="clearFilter()"
                                    style="width: 6rem;"
                                />
                                <div style="display: flex; align-items: center; justify-content: center; gap: 1rem; margin-right: 1.5rem; width: 15rem;">
                                    <label > show Segmentations</label>
                                    <InputSwitch v-model="showSegmentation" />
                                </div>

                                <Dropdown
                                    v-model="filterProgramStatus"
                                    :options="programStatusOptions"
                                    optionLabel="status"
                                    optionGroupLabel="label"
                                    optionGroupChildren="items"
                                    optionValue="status"
                                    placeholder="Filter Status"
                                    :showClear="true"
                                    class="me-4"
                                    style="width: 10rem;"
                                >
                                    <template #optiongroup="slotProps">
                                        <div v-if="!slotProps.option.label" class="flex align-items-center">
                                            <hr>
                                        </div>
                                        <div v-else>
                                        </div>
                                    </template>
                                </Dropdown>

                                <span class="p-input-icon-left" style="position: relative;">
                                    <i class="pi pi-search" style="position: absolute; left: 5%; top:50%"  />
                                    <InputText
                                        v-model="filters['global'].value"
                                        placeholder="Keyword Search"
                                        class="keywordSearch" 
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- ProgramName  -->
                <Column
                    v-for="col of columns"
                    :field="col.field"
                    :header="col.header"
                    :key="col.field"
                    :sortable="true"
                >
                    <template #body="slotProps">
                        <div
                            @click="open(slotProps.data)"
                            :style="{'cursor':'pointer'}"
                        >
                        {{slotProps.data.name}}
                        </div>
                    </template>
                </Column>
                <!-- ProgramDate -->
                <Column
                    :field="'dateAdded'"
                    :header="'Date Updated'"
                    :sortable="true"
                >
                    <template #body="slotProps">
                        <div>
                            {{ formatDate(slotProps.data.documentProgramTopics[0].programDate) }}
                        </div>
                    </template>
                </Column>
                <!-- Segmentations -->
                <div 
                    v-for="(segmentation) in selectedTopic.segmentations.sort((a,b)=> a.order - b.order)" 
                    :key="segmentation.segmentationId"
                >
                    <div v-if="segmentation.isRegional">
                        <Column
                            :field="'Country'"
                            :header="'Country'"
                            :key="segmentation.segmentationId"
                            :sortable="true"
                        >
                            <template #body="slotProps">
                                <div v-if="showSegmentation">
                                    <div 
                                        v-if ="slotProps.data.documentProgramTopics[0].docProgramTopicSegItems.filter(docProgramTopicSegItem => docProgramTopicSegItem.segmentationId === segmentation.segmentationId )[0]"
                                    >
                                        {{getCountryName(slotProps.data.documentProgramTopics[0].docProgramTopicSegItems.filter(docProgramTopicSegItem =>  docProgramTopicSegItem.segmentationId === segmentation.segmentationId )[0].regionCountry.countryId)  }}
                                    </div>
                                    <div v-else style="color: #8B0000; background-color: #FFCCCB;">
                                        not selected
                                    </div>
                                </div>
                                <div v-else>
                                    <div>
                                        ---
                                    </div>
                                </div>
                            </template>
                        </Column>
                    </div>
                    <div v-else>
                        <Column
                            :header="segmentation.name"
                            :key="segmentation.segmentationId"
                            :field="segmentation.segmentationId"
                            :sortable="true"
                        >
                            <template #body="slotProps"> 
                                <div v-if="showSegmentation">
                                    <div v-if="slotProps.data.documentProgramTopics[0].docProgramTopicSegItems.filter(docProgramTopicSegItem => docProgramTopicSegItem.segmentationId === segmentation.segmentationId )[0]">
                                        {{ slotProps.data.documentProgramTopics[0].docProgramTopicSegItems.filter(docProgramTopicSegItem => docProgramTopicSegItem.segmentationId === segmentation.segmentationId )[0].segmentationName  }}
                                    </div>
                                    <div v-else style="color: #8B0000; background-color: #FFCCCB;">
                                        not selected
                                    </div>
                                </div>
                                <div v-else>
                                    <div>
                                        ---
                                    </div>
                                </div>
                            </template>
                        </Column>
                    </div>
                </div>
                <!-- Document Count -->
                <Column
                    :header="'Document count'"
                    :key="'Document count'"
                    bodyStyle="text-align: right"
                    style="width: 5rem;"
                >
                    <template #body="slotProps">
                        {{slotProps.data.totalDocuments }}
                    </template>
                </Column>

                <!-- Status -->
                <Column
                    :header="'Status'"
                    :field="'status'"
                    :sortable="true"
                >
                </Column>
                
                <!-- edit, remove Button  -->
                <Column :exportable="false" style="width: 7rem">
                    <template #body="slotProps">
                        <!-- <Button
                            icon="pi pi-pencil"
                            class="p-button-rounded p-button-success mx-2"
                            @click="openEditDialog(slotProps.data)"
                        /> -->
                        <div style="display: grid; place-items: center; padding: 0;">
                            <Button
                                icon="pi pi-trash"
                                class="p-button-rounded p-button-danger"
                                @click="openDeleteDialog(slotProps.data)"
                            />
                        </div>
                        
                    </template>
                </Column>

                <!-- editMultiplesPrograms  -->
                <Column 
                >
                <!-- style="display: flex; justify-content: center;" -->
                    <template #header>
                        <Checkbox
                            v-model="programCheckAll"
                            :binary="true"
                        />
                    </template>
                    <template #body="slotProps">
                        <div style="display: flex; justify-content: center;">
                            <Checkbox
                                v-model="programCheck"
                                :inputId="slotProps.data.documentProgramTopics[0].documentProgramTopicId" 
                                :value="slotProps.data.documentProgramTopics[0].documentProgramTopicId" 
                            />
                        </div>
                    </template>
                </Column>

                <template #paginatorstart>
                    <Button
                        type="button"
                        icon="pi pi-refresh"
                        class="p-button-text" 
                    />
                </template>
                <template #footer>
                    <div style="display:grid; place-items: center;" v-if="programCheck.length > 0 ">
                        <Button type="button" label="Edit Multiple Programmes"  class="p-button-info"
                        @click="openEditMultiplePrograms()" />
                    </div>
                    <div style="display:grid; place-items: center;" v-else>
                        <Button type="button" label="Edit Multiple Programmes"  class="p-button-info"
                        @click="openEditMultiplePrograms()" disabled />
                    </div>
                </template>
            </DataTable>
        </div>
    </div>
    <div v-else>
        <div class="row">
            <div class="col-12 py-4 d-flex justify-content-center">
            <ProgressSpinner />
            </div>
        </div>
    </div>
    <EditProgram 
        :selectedElement= "selectedEditRow"
        @closeDialog="closeDialog($event)" 
    />
    
    <DeleteProgram 
        :selectedElement= "selectedDeleteRow"
        @closeDialog="closeDialog($event)" 
    />
</template>

<script>
import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import { FilterMatchMode , FilterOperator } from "primevue/api";
import Dropdown from "primevue/dropdown";
import InputSwitch from "primevue/inputswitch";
// import { useToast } from "vue-toastification";
import CreateProgram from "./CreateProgram.vue";
import DeleteProgram from "./DeleteProgram.vue";
import EditProgram from "./EditProgram.vue";
import formatToGlobalDate from "../../../../shared/helpers/date.helper";
import redirectHelperPage from "../../../../shared/helpers/redirectToHelperPage";

// const toast = useToast();

export default {
    components: {
        ProgressSpinner,
        DataTable,
        Button,
        InputText,
        Column,
        Checkbox,
        // components
        CreateProgram,
        DeleteProgram,
        EditProgram,
        Dropdown,
        InputSwitch,
    },
    props:['fromTopic'],
    data(){
        return {
            columns:null, 
            loading: false,
            filters:null,
            selectedEditRow: null,
            selectedDeleteRow:null,
            programCheck:[],

            programCheckAll:false,

            //sortValues
            sortFieldValue: null,
            sortOrderValue: null,
            //paginationCountValues
            firstPagValue: null,
            rowsPagValue:25,
            
            // filters
            filterProgramStatus:null, 

            // showSegmentations
            showSegmentation :false,

            // for setALl 
            sortFieldForSetAll: null,
            sortOrderForSetAll:null,
            firstForSetAll:0,

        }
    },
    created(){
        this.columns = [
            {field: 'name' , header: 'Name'}
        ];
        this.initFilters();
    },
    async mounted(){
        // gets Request
        await this.getCountries();
        await this.obtainStatus(); 
        await this.getSegmentations();

        await this.getPrograms();
        this.cleanDocumentProgramTopicIds();
        this.setProgramSort();
    },

    computed: {
        selectedTopic() {
            return this.$store.getters.obtainSelectedTopic;
        },

        countries(){
            return this.$store.getters.obtainCountries;
        },

        topicSegmentationsItems(){  // get List of topicSegmentationsItems notRegional, 
            let segmentations = this.$store.getters.obtainSegmentations;
            if(segmentations.length > 0){
                return segmentations
                    // .filter(seg => !seg.isRegional )
                    .flatMap(seg => seg.segmentationItems)
            }
            else{
                return null
            }
        },

        programStatus(){
            return  this.$store.getters.obtainProgramStatus;
        },

        programs(){
            let progs; 
            if(this.selectedTopic){
                progs = this.$store.getters.obtainPrograms.map(prog =>
                {
                    // countrySegmentation 
                    if(prog.documentProgramTopics[0].docProgramTopicSegItems.filter(segItem =>segItem.regionCountry)[0]){
                        prog = { 
                        ...prog , 
                        'dateAdded' : prog.documentProgramTopics[0].programDate,
                        'Country': this.getCountryName(prog.documentProgramTopics[0].docProgramTopicSegItems.filter(segItem =>segItem.regionCountry)[0].regionCountry.countryId),
                        'status': this.getProgramStatusName(prog.documentProgramTopics[0].documentProgramStatusId)
                        }
                    }
                    else{
                        prog = { 
                        ...prog , 
                        'dateAdded' : prog.documentProgramTopics[0].programDate,
                        'Country': 'segmentationItem Not Found',
                        'status': this.getProgramStatusName(prog.documentProgramTopics[0].documentProgramStatusId)
                        }
                    }
                    // console.log(this.topicSegmentationsItems);

                    // Segmentations 
                    const docProgramTopicSegItems = prog.documentProgramTopics[0].docProgramTopicSegItems;
                    // const filteredItems = docProgramTopicSegItems.filter(TopSegItems => TopSegItems.regionCountry === null );

                    docProgramTopicSegItems.map((TopSegItems) => {
                        let segItemName = null
                        let segmentationId = null


                        this.topicSegmentationsItems.forEach(seg => {
                            const foundItem = seg.segmentationItemId === TopSegItems.segmentationItemId
                            if(foundItem){
                                segItemName = seg.name
                                segmentationId = seg.segmentationId
                            }
                        })

                        if(TopSegItems.regionCountry !== null){
                            if(segmentationId !== null){
                                TopSegItems.segmentationId = segmentationId;
                            }
                            else{
                                TopSegItems.segmentationId = null;
                            }
                        }

                        else{
                            if(segItemName !== null){
                                TopSegItems.segmentationName = segItemName;
                                TopSegItems.segmentationId = segmentationId;
                                prog[segmentationId] = segItemName
                            }
                            else{
                                TopSegItems.segmentationName = null;
                                TopSegItems.segmentationId = null;
                                prog[segmentationId] = "segmNotFound"
                            }
                        }
                    })

                    return prog
                });
                // console.log('progs ', progs);
                // console.log('selectedTopic', this.selectedTopic);
                
            }

            if(this.filterProgramStatus){
                if(this.filterProgramStatus === "Status not set"){
                    progs = progs.filter(program => program.status === "")
                }
                else{
                    progs = progs.filter(program => program.status === this.filterProgramStatus)
                }
            }

            return progs;
        },
        
        programStatusOptions(){
            const status = this.$store.getters.obtainProgramStatus;
            const statusFIlterOptions = [
                {
                    label: 'status',
                    items: status
                },
                {
                    label: null,
                    items: [
                        {
                            "documentProgramStatusId": "StatusNotSet",
                            "status": "Status not set"
                        }
                    ]
                }
            ]
            return statusFIlterOptions; 
        },

        programSort(){
            if(this.fromTopic){
                const programSortTopic = this.$store.getters.obtainProgramSortFromTopicId;
                try {
                    if(this.selectedTopic.topicId === programSortTopic.topicId){
                        return programSortTopic;
                    }
                    else{
                        const programSortFromTopicId = {
                            sortField : null,
                            sortOrder: null,
                            firstPagValue: null,
                            rowsPagValue : null,
                        } 
                        return programSortFromTopicId
                    }
                } catch (error) {
                    const programSortFromTopicId = {
                        sortField : null,
                        sortOrder: null,
                        firstPagValue: null,
                        rowsPagValue : null,
                    }
                    return programSortFromTopicId
                }
            }
            else{
                // console.log('log desde no topic');
                return this.$store.getters.obtainDocumentSort;
            }
        },


        // for setAll for the programs that only see 
        sortedProductsForSetAll(){
            if(!this.sortFieldForSetAll)return [...this.programs];

            return [...this.programs].sort((a,b) => {
                return this.sortOrderForSetAll * (a[this.sortFieldForSetAll] > b[this.sortFieldForSetAll] ? 1 : -1);
            })
        },
        // for setAll for the programs that only see 
        paginatedProductsForSetAll() {
            const start = this.firstForSetAll;
            const end = start + this.rowsPagValue;
            return this.sortedProductsForSetAll.slice(start, end);
        }


    },
    methods: {
        goToHeaderPage(section){
            redirectHelperPage(section)
        },

        async getCountries() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainCountries');
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },

        async getSegmentations(){
            try {
                this.loading = true;
                await this.$store.dispatch('obtainSegmentations', this.selectedTopic.topicId);
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },

        async getPrograms(){
            try{
                this.loading= true;
                if(this.fromTopic){
                    if(this.showSegmentation){
                        // topicID Required, withDetails is the manufacurer , withSegItems is the segmentation segmentation brings only ID ,   totalDocuments with totals
                        await this.$store.dispatch('obtainProgramsByTopicId', {topicId:this.selectedTopic.topicId , withDetails: false, withSegItems: true, totalDocuments: true })
                    }
                    else{
                        // TODO change params endpoints
                        await this.$store.dispatch('obtainProgramsByTopicId', {topicId:this.selectedTopic.topicId , withDetails: false, withSegItems:false, totalDocuments: true })
                    }
                    // await this.$store.dispatch('obtainProgramsByTopicId', {topicId:this.selectedTopic.topicId , withDetails: true, totalDocuments: true })
                }
                else{
                    await this.$store.dispatch('obtainPrograms' );
                }
                
                this.loading = false;
            }
            catch(error){
                // toast.error(error.message);
                this.loading=false;
            }
        },
        async obtainStatus(){
            try {
                this.loading= true;
                await this.$store.dispatch('obtainProgramStatus');
                this.loading = false;
            } catch (error) {
                this.loading = false; 
            }
        },

        setProgramSort(){
            try {
                //sort
                this.sortFieldValue = this.programSort.sortField;
                this.sortOrderValue = this.programSort.sortOrder;

                //pag 
                if(this.programSort.firstPagValue === null){
                    this.firstPagValue = 0
                }
                else{
                    this.firstPagValue =this.programSort.firstPagValue;
                }
                if(this.programSort.rowsPagValue === null){
                    this.rowsPagValue = 25
                }
                else{
                    this.rowsPagValue = this.programSort.rowsPagValue
                }

                this.showSegmentation = this.programSort.showSegmentation

                // programStatus
                this.filterProgramStatus = this.programSort.status;

                //keywordSearch
                this.filters = {
                    global: {value : this.programSort.keywordSearch , matchMode: FilterMatchMode.CONTAINS},
                    name: {
                        operator: FilterOperator.AND,
                        constraints: [
                            {value: null, matchMode:  FilterMatchMode.STARTS_WITH },
                        ],
                    },
                };

            } catch (error) {
                console.log(error);
            }
        },
        initFilters() {
            this.filters = {
                global: {value : null , matchMode: FilterMatchMode.CONTAINS},
                name: {
                    operator: FilterOperator.AND,
                    constraints: [
                        {value: null, matchMode:  FilterMatchMode.STARTS_WITH },
                    ],
                },
            };
        },
        clearFilter(){
            this.initFilters();
            this.filterProgramStatus = null;
            this.showSegmentation= false;
        },
        formatDate(date){
            return formatToGlobalDate(date)
        },
        getCountryName( countryId ){
            const a = this.countries.find(country => country.countryId=== countryId)
            return a.name 
        },

        getProgramStatusName(programStatusId ){
            const statusName =  this.programStatus.filter(stat => stat.documentProgramStatusId === programStatusId);
            if(statusName[0]){
                return statusName[0].status
            }
            else{
                return ''
            }
        },
        open(event){
            this.$router.push(`/admin/topics/program/overview/${event.documentProgramTopics[0].documentProgramTopicId}`)
        },
        openDeleteDialog(element){
            this.selectedDeleteRow = element; 
        },
        closeDialog(){
            this.selectedEditRow= null;
            this.selectedDeleteRow = null; 
        },

        // editMultiplesPrograms
        cleanDocumentProgramTopicIds(){
            this.$store.dispatch('cleanDocumentProgramTopicIds')
        },

        setAllPrograms(){
            // for only the page we see 
            this.paginatedProductsForSetAll.forEach(program => this.programCheck.push(program.documentProgramTopics[0].documentProgramTopicId))

            // all The programs
            // this.programs.forEach(program => this.programCheck.push(program.documentProgramTopics[0].documentProgramTopicId))
        },
        removeAllPrograms(){
            console.log('All Programs need to be false');
            this.programCheck = []
        },

        openEditMultiplePrograms(){
            this.cleanDocumentProgramTopicIds();
            this.$store.dispatch('addDocumentProgramTopicIds', this.programCheck);
            this.$router.push('./programs/edit')
        },

        //sortFunctions 
        onSort(e){
            // for setAll for the programs that only see 
            if(e.sortField){
                this.sortFieldForSetAll = e.sortField;
                this.sortOrderForSetAll = e.sortOrder;
            }

            if(e.first !== null){
                this.firstForSetAll = e.first;
            }

            //  on sort Remember functionality
            if(this.fromTopic){
                const programSort = {
                    sortField: e.sortField, 
                    sortOrder: e.sortOrder, 
                    firstPagValue: e.first,
                    rowsPagValue: e.rows,
                    topicId : this.selectedTopic.topicId
                } 
                this.$store.dispatch('updateProgramSortFromTopicId', programSort)
            }
            else{
                const programSort = {
                    sortField: e.sortField, 
                    sortOrder: e.sortOrder, 
                    firstPagValue: e.first,
                    rowsPagValue: e.rows,
                } 
                this.$store.dispatch('updateProgramSort', programSort)
            }
        },
        onChangeFilters(){
            if(this.fromTopic){
                const filterDocProgStatus = {
                    topicId: this.selectedTopic.topicId, 
                    showSegmentation: this.showSegmentation,
                    status: this.filterProgramStatus, 
                    keywordSearch : this.filters.global.value
                    
                }
                this.$store.dispatch('updateProgramSortFromTopicIdFilters',filterDocProgStatus)
            }
            else{
                const filterDocProgStatus = {
                    status: this.filterProgramStatus, 
                }
                this.$store.dispatch('',filterDocProgStatus)
            }

        } ,
    },
    watch:{
        filterProgramStatus () {
            this.onChangeFilters();
        },

        'showSegmentation':{
            async handler(){
                this.onChangeFilters();
                await this.getPrograms();
            },
            deep:true,
        },

        'filters.global.value': {
            handler(newVal) {
                if (newVal) {
                    this.onChangeFilters();
                }
            },
            deep: true
        },

        'programCheckAll':{
            handler(newVal){
                if(newVal){
                    this.setAllPrograms();
                }
                else{
                    this.removeAllPrograms();
                }
            }
        }

    }
}
</script>
<style scoped>
.keywordSearch{
  /* padding: 0 0 0 2.5rem  !important; */
  padding: 0.2rem 0 0.2rem 2.5rem  !important;
}
</style>
